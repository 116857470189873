










































































import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import { plainToClass } from 'class-transformer';
import { inject } from 'inversify-props';
import { DefaultExtensions } from '@/utils/tiptap-extensions/default-extensions';
import { ValidationRules } from '@/utils/helpers/validation-rules-helper';
import EmailComposer from '@/components/email-composer.vue';
import UserContactInfo from '@/models/crm/user-contact-info.model';
import ActivityEventModel from '@/models/crm/activity-event.model';
import ClientModel from '@/models/crm/client.model';
import { FormHelper } from '@/utils/helpers/form-helper';
import EmailModel from '@/models/email.model';
import { IKeyValue } from '@/interfaces/key-value.interface';
import { ICalendarEvent } from '@/interfaces/crm/calendar-event.interface';
import dayjs from '@/plugins/dayjs';
import ActivityService from '@/services/crm/activity.service';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import { ActivityEventEmailOptionsEnum } from '@/enums/crm/activity-event-email-options.enum';
import ContentDialog from '@/components/content-dialog.vue';
import ClientService from '@/services/crm/client.service';
import ProspectService from '@/services/crm/prospect.service';

@Component({
  components: {
    EmailComposer,
    ContentDialog,
  },
})
export default class CrmActivityCalendarEventEmailView extends Vue {
  @inject(InjectionIdEnum.CrmActivityService)
  private activityService!: ActivityService;

  @inject(InjectionIdEnum.CrmClientService)
  private clienteService!: ClientService;

  @inject(InjectionIdEnum.CrmProspectService)
  private prospectService!: ProspectService;

  extensions: unknown[] = DefaultExtensions.getExtensionsWithTable();

  formIsValid = true;

  showEmailOptions = false;

  showEmailComboBox = false;

  emailContacts: string[] = [];

  selectedContactsEmails: string[] = [];

  emailOptions = ActivityEventEmailOptionsEnum;

  emailOption = ActivityEventEmailOptionsEnum.Atendente;

  emailModel: EmailModel<IKeyValue<string | number>[]> = plainToClass(EmailModel, {
    cnpj: '',
    from: null,
    to: [],
    cc: [],
    subject: null,
    sendCopy: false,
    sendAttachments: false,
    message: null,
    metadata: [],
  });

  rules = {
    from: [[ValidationRules.arrayLength, 1], ValidationRules.arrayOfEmails],
    to: [[ValidationRules.minArrayLength, 1], ValidationRules.arrayOfEmails],
    subject: [ValidationRules.required],
    message: [ValidationRules.required],
  };

  @Prop({ required: true })
  client!: ClientModel;

  @Prop({ required: true, type: Object })
  event!: ICalendarEvent;

  @Prop()
  eventModel!: ActivityEventModel | null;

  @Prop({ required: true })
  userContactInfo!: UserContactInfo;

  @Prop({ default: false })
  flagEnviarEmail!: boolean;

  @Prop({ default: false })
  accessingFromKanban!: boolean;

  @Prop({ default: false })
  blockSaving!: boolean;

  @Emit('cancel')
  onCancel(): void {
    FormHelper.resetValidation(this.$refs.form as Vue);
  }

  mounted(): void {
    this.showEmailOptions = true;
    if (this.userContactInfo.email) {
      this.emailModel.from = this.userContactInfo.email;
    }

    this.emailModel.subject = this.getDefaultSubject();
    this.emailModel.message = this.getDefaultMessage();

    if (this.eventModel?.anexos && Array.isArray(this.eventModel.anexos)) {
      const filenames = new Set();

      this.eventModel.anexos.forEach((anexo) => {
        if (!filenames.has(anexo.filename)) {
          filenames.add(anexo.filename);
          this.emailModel.metadata?.push({
            filename: anexo.filename.toString(),
            path: anexo.path.toString(),
          });
        }
      });
    }
  }

  async onSend(): Promise<void> {
    if (this.accessingFromKanban) this.blockSaving = true;
    if (FormHelper.validate(this.$refs.form as Vue)) {
      const loader = this.$loading.show({
        container: this.$refs.eventEmailContainer,
        canCancel: false,
      });
      try {
        this.emailModel.cnpj = this.client.cnpjCpf;

        await this.activityService.sendEventByEmail(this.event.id as number, this.emailModel);

        this.$notify.success(this.$t('crm.activityCalendarEventView.successfullySent'));

        this.$emit('complete', this.eventModel);
      } catch (err) {
        this.$notify.error(err && (err as Error).message);
      } finally {
        loader.hide();
      }
    }
  }

  get filenames(): string[] {
    return (this.emailModel.metadata || []).map((item) => item.filename as string);
  }

  private getDefaultSubject(): string {
    return `${this.$t('crm.activityCalendarEventView.defaultSubject', {
      company: this.userContactInfo.nomeEmpresa,
      title: this.event.name,
      date: this.eventDate(this.event),
    })}`;
  }

  private getSendToPlaceholder(): string {
    const userType = this.eventModel?.prospect
      ? 'prospect'
      : 'cliente';

    return `${this.$t('crm.activityCalendarEventEmailView.selectAEmailSubject', { userType })}`;
  }

  private async filterSendTo(): Promise<void> {
    this.emailModel.to = [];

    if (this.emailOption === ActivityEventEmailOptionsEnum.ClienteProspect
      || this.emailOption === ActivityEventEmailOptionsEnum.Ambos) {
      if (this.selectedContactsEmails) {
        this.selectedContactsEmails.forEach((email) => {
          if (!this.emailModel.to.includes(email)) {
            this.emailModel.to.push(email);
          }
        });
      }
    }

    if (this.emailOption === ActivityEventEmailOptionsEnum.ClienteProspect
      || this.emailOption === ActivityEventEmailOptionsEnum.Ambos) {
      if (this.emailContacts) {
        this.emailContacts.forEach((email) => {
          if (email && !this.emailModel.to.includes(email)) {
            this.emailModel.to.push(email);
          }
        });
      }
    }

    if (this.emailOption === ActivityEventEmailOptionsEnum.Atendente
      || this.emailOption === ActivityEventEmailOptionsEnum.Ambos) {
      const attendant = this.eventModel?.atendente?.email;
      if (attendant && !this.emailModel.to.includes(attendant)) {
        this.emailModel.to.push(attendant);
      }
    }

    if (this.emailOption === ActivityEventEmailOptionsEnum.ClienteProspect
      || this.emailOption === ActivityEventEmailOptionsEnum.Ambos) {
      if (this.client && this.client.email) {
        const email = this.client.email.trim();
        if (!this.emailModel.to.includes(email) && email !== '') {
          this.emailModel.to.push(email);
        }
      }
    }
  }

  private getDefaultMessage(): string {
    let msg = '';
    const tipoCliente = this.eventModel?.prospect ? 'Prospect' : 'Cliente';
    const descricao = this.eventModel?.descricao ? this.eventModel?.descricao : '';
    const data = this.eventDate(this.event);
    const styleBold = 'font-size: 16px;';
    const styleHeader = 'background-color:#4fbcb6; text-align: center; padding: 15px;';
    const styleDiv = 'font-size: 14px; border-left: 4px solid #4fbcb6; padding-left: 10px;';
    const styleCol = 'width: 180px !important;';

    msg += `Olá!<br>Segue abaixo, os dados do novo evento gerado pelo(a) atendente: ${this.eventModel?.atendente?.nome}.
    <br><br>`;
    msg += `<div style='${styleHeader}'><b>DADOS DO CLIENTE</b></div><br>`;
    msg += `<div style='${styleDiv}'>`;
    msg += `<table width='100%'><tbody><tr><td style='${styleCol}'><b style='${styleBold}'>${tipoCliente}:</b></td>
    <td>${this.eventModel?.cnpj} -  ${this.eventModel?.nome}</td></tr>`;
    msg += `<tr><td style='${styleCol}'><b style='${styleBold}'>Representante:</b></td>
    <td>${this.eventModel?.representante?.nome}</div></td></tr></tbody></table></div><br><br>`;
    msg += `<div style='${styleHeader}'><b>DADOS DO ATENDIMENTO</b></div><br>`;
    msg += `<div style='${styleDiv}'>`;
    msg += `<table width='100%'><tbody><tr><td style='${styleCol}'><b style='${styleBold}'>Título:</b></td>`;
    msg += `<td>${this.eventModel?.titulo}</td></tr>`;
    if (this.eventModel?.origem) {
      msg += `<tr><td style='${styleCol}'><b style='${styleBold}'>Origem:</b></td>`;
      msg += `<td>${this.eventModel?.origem.descricao}</td></tr>`;
    }
    if (this.eventModel?.tipoHistorico) {
      msg += `<tr><td style='${styleCol}'><b style='${styleBold}'>Tipo de histórico:</b></td>`;
      msg += `<td>${this.eventModel?.tipoHistorico.nome}</td></tr>`;
    }
    msg += `<tr><td style='${styleCol}'><b style='${styleBold}'>Descrição:</b></td><td>${descricao}</td></tr>`;
    msg += `<tr><td style='${styleCol}'><b style='${styleBold}'>Data:</b></td><td>${data}</td></tr></tbody><table>`;
    msg += '</div><br><br>Este e-mail é gerado automaticamente pelo sistema de CRM!';

    if (this.userContactInfo.assinaturaEmail) {
      msg += `<br><br>${this.userContactInfo.assinaturaEmail.replaceAll(/(?:\r\n|\r|\n)/g, '<br>')}`;
    }

    return msg;
  }

  private eventDate(event: ICalendarEvent): string {
    if (!event) {
      return '';
    }

    let formattedDate = this.$d(event.start, event.timed ? 'dateTime' : 'short');

    if (event.end) {
      const isSameDate = dayjs(event.start).isSame(event.end);
      formattedDate += !isSameDate ? ` - ${this.$d(event.end, event.timed ? 'dateTime' : 'short')}` : '';
    }

    return formattedDate;
  }

  private handleRadioChange() {
    const isClienteProspect = this.emailOption === ActivityEventEmailOptionsEnum.ClienteProspect;

    if (isClienteProspect) {
      this.fetchComboBoxItems();
    } else {
      this.selectedContactsEmails = [];
    }

    this.showEmailComboBox = isClienteProspect;
  }

  private async fetchComboBoxItems() {
    let contacts: Array<string> = [];

    if (this.eventModel) {
      if (this.eventModel.prospect) {
        contacts = await this.prospectService.getContacts(Number(this.eventModel.prospect.codProspect));

        if (!contacts.includes(this.eventModel.prospect.email)) {
          contacts.push(this.eventModel.prospect.email);
        }
      } else {
        contacts = await this.clienteService.getClientContacts(this.client.cnpjCpf);
      }
    }

    if (!contacts) {
      return;
    }

    contacts.forEach((contact) => {
      this.emailContacts.push(contact);
    });
  }
}
